import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  createCustomer,
  getCustomerById,
} from './customers-duck/customerActions';
import { setAlert } from '../alert/alert-duck/alertActions';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import Spinner from '../../components/common/Spinner';

import isEmpty from '../../validation/is-empty';
import axios from 'axios';

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      address: '',
      phoneNumber: '',
      note: '',
      isSupplier: false,
      isDeleting: false,

      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      getCustomerById,
    } = this.props;
    getCustomerById(params.id);
  }

  onSubmit(e) {
    e.preventDefault();

    const customerData = {
      id: this.state.id,
      name: this.state.name,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      note: this.state.note,
      isSupplier: this.state.isSupplier,
    };
    this.props.createCustomer(customerData, this.props.history, false);
  }

  handleDelete = (e) => {
    e.preventDefault();
    if (!!window.confirm('האם תרצה למחוק הלקוח הזה!')) {
      this.setState({ isDeleting: true });
      axios
        .delete(`/api/customers/${this.state.id}`)
        .then((res) => {
          this.props.setAlert('הלקוח נמחק בהצלחה', 'info');
          this.props.history.push('/customers');
        })
        .catch((err) => {
          this.setState({ isDeleting: false });
          this.props.setAlert('אין אפשרות למחוק הלקוח הזה', 'danger');
        });
    }
  };

  onChange(e) {
    const target = e.target;

    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'image') {
      this.setState({ image: target.files });
      return;
    }

    this.setState({ [name]: value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.customer.customer) {
      const customer = nextProps.customer.customer;
      // if customer field doesnt exist, make empty string
      customer.name = !isEmpty(customer.name) ? customer.name : '';
      customer.note = !isEmpty(customer.note) ? customer.note : '';

      customer.phoneNumber = !isEmpty(customer.phoneNumber)
        ? customer.phoneNumber + ''
        : '';
      customer.address = !isEmpty(customer.address) ? customer.address : '';

      this.setState({
        id: customer._id,
        name: customer.name,
        phoneNumber: customer.phoneNumber,
        address: customer.address,
        note: customer.note,
        isSupplier: customer.isSupplier,
      });
    }
  }

  render() {
    const { errors, isDeleting, isSupplier } = this.state;

    return (
      <div className="create-product">
        <div className="container">
          <div className="row">
            {isDeleting ? (
              <Spinner />
            ) : (
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center">
                  עדכון {isSupplier ? 'ספק' : 'לקוח'} קיים
                </h1>
                <p className="lead text-center">
                  טופס עדכון {isSupplier ? 'ספק' : 'לקוח'} קיים במערכת
                </p>
                <small className="d-block pb-3">שדות חובה = *</small>
                <form onSubmit={this.onSubmit}>
                  <TextFieldGroup
                    placeholder="* שם"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                    error={errors.name}
                  />
                  <TextFieldGroup
                    placeholder="* כתובת"
                    name="address"
                    value={this.state.address}
                    onChange={this.onChange}
                    error={errors.address}
                  />
                  <TextFieldGroup
                    placeholder="* מס' נייד"
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                    onChange={this.onChange}
                    error={errors.phoneNumber}
                    type="numbers"
                    info="מספר טלפון נייד כולל קידומת"
                  />
                  {!isSupplier && (
                    <TextFieldGroup
                      placeholder="מצב לקוח"
                      name="note"
                      value={this.state.note}
                      onChange={this.onChange}
                      error={errors.note}
                    />
                  )}
                  <div className="checkbox mt-3">
                    <label>
                      <input
                        type="checkbox"
                        value={isSupplier}
                        checked={isSupplier ? 1 : 0}
                        onChange={this.onChange}
                        name="isSupplier"
                        disabled
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      <span className="mr-2">ספק</span>
                    </label>
                  </div>

                  <input
                    type="submit"
                    value="ערוך"
                    className="btn btn-info btn-block mt-4"
                  />
                </form>
                <button
                  className="btn btn-danger btn-block mt-4"
                  onClick={this.handleDelete}
                >
                  מחק
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

EditCustomer.propTypes = {
  createCustomer: PropTypes.func.isRequired,
  getCustomerById: PropTypes.func.isRequired,
  setAlert: PropTypes.func,
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createCustomer,
  getCustomerById,
  setAlert,
})(withRouter(EditCustomer));
