import axios from 'axios';
//import { saveAs } from 'file-saver';

import {
  GET_DOCUMENT,
  GET_DOCUMENTS,
  DOCUMENT_LOADING,
  GET_ERRORS,
  DOWNLOAD_DOCUMENT,
} from '../../../app-duck/actions/types';

import { setAlert } from '../../alert/alert-duck/alertActions';

//Get document by id
export const getDocumentById = (id) => (dispatch) => {
  dispatch(setDocumentLoading());
  axios
    .get(`/api/documents/${id}`)
    .then((res) => {
      dispatch({
        type: GET_DOCUMENT,
        payload: res.data[0],
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Create PDF from Document by id
export const downloadPDF = (id, fileName) => (dispatch) => {
  dispatch(setDocumentLoading());
  axios
    .get(`/api/pdf/${id}`, { responseType: 'blob' })
    .then((res) => {
      dispatch({
        type: DOWNLOAD_DOCUMENT,
      });
      //const pdf = new Blob([res.data], { type: 'application/pdf' });

      //saveAs(pdf, fileName);
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

//Get all document
export const getAllDocuments = () => (dispatch) => {
  dispatch(setDocumentLoading());
  axios
    .get(`/api/documents/all`)
    .then((res) =>
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Get document by customer id
export const getCustomerDocuments = (customerId) => (dispatch) => {
  dispatch(setDocumentLoading());
  axios
    .get(`/api/documents/customer/${customerId}`)
    .then((res) =>
      dispatch({
        type: GET_DOCUMENTS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Search into receipt checks
export const searchChecks = (number, account) => (dispatch) => {
  dispatch(setDocumentLoading());
  axios
    .get(`/api/receipt/search/${number}/${account}`)
    .then((res) => dispatch({ type: GET_DOCUMENTS, payload: res.data }))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: 'חסר נתון',
      });
      dispatch(setAlert('חסר נתון', 'error'));
    });
};

// Document Loading
export const setDocumentLoading = () => {
  return {
    type: DOCUMENT_LOADING,
  };
};
