import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTransactionReport } from '../transaction/transaction-duck/transactionAction';
import Spinner from '../../components/common/Spinner';
import TextFieldGroup from '../../components/common/TextFieldGroup';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: '',
      end: '',
      orderIndex: 1,
      saleIndex: 1,
      data: null,
      loading: true,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.transaction && this.props.transaction.report) {
      this.setState({ data: this.props.transaction.report, loading: false });
    } else {
      this.props.getTransactionReport();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.transaction && nextProps.transaction.report) {
      this.setState({ data: nextProps.transaction.report, loading: false });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { start, end } = this.state;
    this.setState({ loading: true });
    this.props.getTransactionReport(start, end);
  }

  render() {
    const axes = [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { type: 'linear', position: 'left', stacked: true },
    ];

    const { loading, start, end, data, orderIndex, saleIndex } = this.state;

    if (loading) return <Spinner />;

    const chartData = [
      {
        label: 'Buy',
        data: data.buy.map((d, i) => [d.name, d.count]),
      },
    ];
    const saleChartData = [
      {
        label: 'Sale',
        data: data.sale.map((d, i) => [d.name, d.count]),
      },
    ];
    const orderChartData = [
      {
        label: 'Order',
        data: data.order.map((d, i) => [
          (d.name + '     ').substring(0, 5),
          d.count,
        ]),
      },
    ];

    const options = {
      scales: {
        xAxes: [
          {
            barPercentage: 0.7,
          },
        ],
      },
    };
    return (
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center">Dashboard</h1>
              <hr />
              <div className="row">
                <div className="col-md-5">
                  <TextFieldGroup
                    name="start"
                    value={start}
                    onChange={this.onChange}
                    type="date"
                  />
                </div>
                <div className="col-md-5">
                  <TextFieldGroup
                    name="end"
                    value={end}
                    onChange={this.onChange}
                    type="date"
                  />
                </div>
                <div className="col-md-2 pt-1">
                  <button
                    onClick={this.onSubmit}
                    className="btn btn-info btn-block"
                  >
                    החל
                  </button>
                </div>
              </div>

              <hr />
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <center>
                        <h3>דוח יצור</h3>
                      </center>
                    </div>

                    <div className="col-md-12">
                      <table className="table table-striped statics-table">
                        <thead>
                          <tr>
                            <th>שם</th>
                            <th>כמות</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.order.map((b, i) => {
                            if (i < 10 * orderIndex) {
                              return (
                                <tr key={i}>
                                  <th>{b.name}</th>
                                  <th>{b.count}</th>
                                </tr>
                              );
                            }
                          })}
                          {data.order.length > 10 * orderIndex && (
                            <tr>
                              <th
                                onClick={() =>
                                  this.setState({
                                    orderIndex: orderIndex + 1,
                                  })
                                }
                              >
                                <a href="#">Show More</a>
                              </th>
                              <th></th>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <center>
                        <h3>דוח מכירות </h3>
                      </center>
                    </div>

                    <div className="col-md-12">
                      <table className="table table-striped statics-table">
                        <thead>
                          <tr>
                            <th>שם</th>
                            <th>כמות</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.sale.map((b, i) => {
                            if (i < 10 * saleIndex) {
                              return (
                                <tr key={i}>
                                  <th>{b.name}</th>
                                  <th>{b.count}</th>
                                </tr>
                              );
                            }
                          })}
                          {data.sale.length > 10 * saleIndex && (
                            <tr>
                              <th
                                onClick={() =>
                                  this.setState({
                                    saleIndex: saleIndex + 1,
                                  })
                                }
                              >
                                <a href="#">Show More</a>
                              </th>
                              <th></th>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  getTransactionReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  transaction: state.transaction,
});

export default connect(mapStateToProps, { getTransactionReport })(Dashboard);
