import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/common/Spinner';
import { getAllProducts } from '../products/products-duck/productAction';
import { getCustomers } from '../customers/customers-duck/customerActions';
import { createTransaction } from './transaction-duck/transactionAction';
import CustomerSelect from '../customers/CustomerSelect';
import Autosuggest from 'react-autosuggest';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TRANSACTION_TYPES } from '../../utils/const';

export class Sale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: null,
      customers: null,
      selectedCustomer: null,

      value: '',
      suggestions: [],
      selectedProducts: [],

      sending: false,
    };

    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const { selectedCustomer, selectedProducts } = this.state;

    const documentData = {
      type: TRANSACTION_TYPES.SALE,
      customer: selectedCustomer._id,
      components: selectedProducts.map((sbp) => ({
        product: sbp._id,
        quantity: sbp.quantity,
      })),
    };

    this.setState({ sending: true });
    this.props.createTransaction(documentData, this.props.history);
  }

  componentDidMount() {
    if (this.props.product.products) {
      this.setState({ products: this.props.product.products });
    } else {
      this.props.getAllProducts();
    }

    if (this.props.customer.customers) {
      this.setState({
        customers: this.props.customer.customers.filter(
          (cus) => cus.isSupplier
        ),
      });
    } else {
      this.props.getCustomers();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product && nextProps.product.products) {
      this.setState({ products: nextProps.product.products });
    }
    if (nextProps.customer && nextProps.customer.customers) {
      this.setState({
        customers: nextProps.customer.customers.filter(
          (cus) => !cus.isSupplier
        ),
      });
    }
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  //#region Product suggestion
  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp(escapedValue, 'i');
    return this.state.products.filter((product) =>
      regex.test(product.name + ' ' + product.recordId)
    );
  }

  setSelectedProduct(product) {
    const { selectedProducts } = this.state;
    product = {
      ...product,
      quantity: 1,
    };
    selectedProducts.push(product);
    this.setState({ value: '' });
  }

  onSuggestionSelected(
    suggestion,
    suggestionValue,
    suggestionIndex,
    sectionIndex,
    method
  ) {
    this.setSelectedProduct(suggestionValue.suggestion);
    this.clearInput();
  }

  getSuggestionValue(suggestion) {
    return suggestion.name + ' ' + suggestion.recordId;
  }

  renderSuggestion(suggestion) {
    return (
      <span>
        {suggestion.name + ' '} <small>{suggestion.recordId}</small>
      </span>
    );
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  onProductChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  clearInput = () => {
    this.setState({
      value: '',
    });
  };

  //#endregion

  getCellActions = (column, row) => {
    const cellActions = [
      {
        icon: <i className="fas fa-trash-alt text-dark" />,
        callback: () => {
          this.setState({
            selectedProducts: this.arrayRemove(
              this.state.selectedProducts,
              row
            ),
          });
        },
      },
    ];
    return column.key === 'action' ? cellActions : null;
  };
  arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    // Check if quantity is number
    if (updated.quantity) {
      if (isNaN(updated.quantity)) {
        return;
      }
    }

    // Update base product
    this.setState((state) => {
      const selectedProducts = state.selectedProducts.slice();

      let quantity = updated.quantity;

      for (let i = fromRow; i <= toRow; i++) {
        selectedProducts[i] = {
          ...selectedProducts[i],
          ...updated,
          currentQuantity: Number(
            Number(
              Number(selectedProducts[i].currentQuantity) + Number(quantity)
            ).toFixed(2)
          ),
        };
      }
      return { selectedProducts };
    });
  };

  render() {
    const {
      products,
      customers,
      selectedCustomer,
      selectedProducts,
      sending,
      value,
      suggestions,
    } = this.state;
    if (!products || !customers) {
      return <Spinner />;
    }

    const inputProps = {
      placeholder: 'שם | מק׳׳ט',
      className: 'form-control',
      value,
      onChange: this.onProductChange,
    };

    const productsColumns = [
      { key: 'action', name: 'מחק' },
      { key: 'currentQuantity', name: 'מלאי' },
      { key: 'quantity', name: 'כמות', editable: true },
      { key: 'name', name: 'שם' },
      { key: 'recordId', name: 'מק׳׳ט' },
    ];

    return (
      <div className="buy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4 text-center">מסמך מכירה</h1>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <CustomerSelect
                      setSelectedCustomer={(customer) => {
                        this.setState({ selectedCustomer: customer });
                      }}
                      customers={customers}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h4>בחר מוצר</h4>
                  <div className="form-group">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      onSuggestionSelected={this.onSuggestionSelected}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps}
                    />
                    <br />
                    <div dir="ltr">
                      <ReactDataGrid
                        columns={productsColumns}
                        rowGetter={(i) => selectedProducts[i]}
                        onGridRowsUpdated={this.onGridRowsUpdated}
                        minHeight={500}
                        rowsCount={selectedProducts.length + 1}
                        getCellActions={this.getCellActions}
                        callSelection={true}
                        enableCellSelect={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="submit"
                  value="בצע"
                  className={classnames('btn btn-secondary btn-lg btn-block', {
                    'btn-success':
                      selectedCustomer && selectedProducts.length !== 0,
                  })}
                  disabled={
                    !selectedCustomer ||
                    selectedProducts.length === 0 ||
                    sending
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Sale.propTypes = {
  product: PropTypes.object,
  customer: PropTypes.object,
  getAllProducts: PropTypes.func,
  getCustomers: PropTypes.func,
  createTransaction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  product: state.product,
  customer: state.customer,
});

const mapDispatchToProps = {
  getAllProducts,
  getCustomers,
  createTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sale);
