import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProductById } from "./products-duck/productAction";
import { getProductTransactions } from "../transaction/transaction-duck/transactionAction";
import isEmpty from "../../validation/is-empty";
import Spinner from "../../components/common/Spinner";
import { IMAGE_BASE_URL, TRANSACTION_TYPES } from "../../utils/const";
import { Link } from "react-router-dom";
import * as COLOR from "../../utils/colors";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      transactions: null,
      tranIndex: 1,
      selectedLogo: "solido",
    };
  }

  componentDidMount() {
    try {
      const { id } = this.props.match.params;
      this.props.getProductById(id);
      this.props.getProductTransactions(id);
    } catch (err) {
      this.props.history.goBack();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.product && nextProps.product.product) {
      this.setState({ product: nextProps.product.product });
    }
    if (nextProps.transaction && nextProps.transaction.transactions) {
      this.setState({ transactions: nextProps.transaction.transactions });
    }
    if (!isEmpty(nextProps.errors)) {
      this.props.history.goBack();
    }
  }

  render() {
    const { product, transactions, tranIndex } = this.state;
    if (!product) {
      return <Spinner />;
    }

    return (
      <div className="container print">
        <div className="row">
          <div className="row">
            <div className="col text-left">
              <button
                className="hide-print"
                onClick={() => {
                  window.print();
                }}
              >
                הדפס {PrintIcon}
              </button>
              <select
                className="hide-print"
                onClick={(e) => {
                  this.setState({
                    selectedLogo: e.target.value,
                  });
                }}
              >
                <option value="solido">Solido</option>
                <option value="mrg">MRG</option>
              </select>
            </div>
          </div>
          <div className="col-md-12 m-auto">
            <div className="row mt-4">
              <div className="col-4 text-center">
                <div className="print-only">
                  <img
                    className=""
                    src={
                      process.env.PUBLIC_URL +
                      (this.state.selectedLogo == "solido"
                        ? "/img/solido.png"
                        : "/img/mrg.png")
                    }
                    alt="logo"
                    //style={{ width: '25px', marginRight: '5px' }}
                  />
                </div>
                <h3 className="display-5">{product.name}</h3>
                <p>{product.recordId}</p>

                <div className="hide-print">
                  {product.color && (
                    <label>
                      צבע: <span>{product.color}</span>
                    </label>
                  )}
                  <h4>
                    מלאי: <span>{product.currentQuantity}</span>
                  </h4>
                  {product.costPrice && (
                    <label>
                      מחיר עלות: <span>{product.costPrice}</span>
                    </label>
                  )}
                </div>
              </div>
              <div className="col-8">
                <img
                  src={`${IMAGE_BASE_URL}/w_800,h_500,c_fill/${product.image.url}`}
                  alt={product.name}
                />
              </div>
            </div>
            <hr />
            <div className="row hide-print">
              <div className="col-md-12">
                <label>תיאור:</label>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeURIComponent(product.description),
                  }}
                />

                <br />
                <hr />
                <label>מרכיבים:</label>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>מק׳׳ט</th>
                      <th>חומר גלם</th>
                      <th>כמות</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.components.map((comp) => (
                      <tr key={comp.baseProduct.recordId}>
                        <th>{comp.baseProduct.recordId}</th>
                        <th>{comp.baseProduct.name}</th>
                        <th>{comp.amount}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {transactions && transactions.length && (
          <div className="row hide-print mt-5">
            <div className="col-md-12 mt-5">
              <center>
                <h3>History</h3>
              </center>
            </div>
            <div className="col-md-12">
              <table className="table table-striped statics-table">
                <thead>
                  <tr style={{ backgroundColor: "#fff" }}>
                    <th>תאריך</th>
                    <th>סוג פעולה</th>
                    <th>כמות לפני</th>
                    <th>כמות</th>
                    <th>כמות אחרי</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((t, i) => {
                    if (i < 10 * tranIndex) {
                      return (
                        <tr
                          key={t.id}
                          style={{
                            backgroundColor:
                              t.type == TRANSACTION_TYPES.SALE
                                ? COLOR.GREEN_BACKGROUND
                                : t.type === TRANSACTION_TYPES.ORDER
                                ? COLOR.YELLOW_BACKGROUND
                                : null,
                          }}
                        >
                          <th>
                            {new Date(t.createdAt).toISOString().split("T")[0]}
                          </th>
                          <th>
                            {t.type === TRANSACTION_TYPES.BUY
                              ? "רכישה"
                              : t.type === TRANSACTION_TYPES.ORDER
                              ? "יצור"
                              : t.type === TRANSACTION_TYPES.SALE
                              ? "מכירה"
                              : null}
                          </th>
                          <th>{t.quantityBefore}</th>
                          <th>{t.quantity}</th>

                          <th>
                            {t.quantityBefore +
                              (t.type == TRANSACTION_TYPES.SALE ? -1 : 1) *
                                t.quantity}
                          </th>
                          <th>
                            <Link to={`/transaction/${t.id}`}>צפיה</Link>{" "}
                          </th>
                        </tr>
                      );
                    }
                  })}
                  {transactions.length > 10 * tranIndex && (
                    <tr>
                      <th
                        onClick={() =>
                          this.setState({
                            tranIndex: tranIndex + 1,
                          })
                        }
                      >
                        <a href="#">Show More</a>
                      </th>
                      <th></th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Product.propTypes = {
  getProductById: PropTypes.func,
  clearCurrentProduct: PropTypes.func,
  getProductTransactions: PropTypes.func,

  product: PropTypes.object,
};

const mapStateToProps = (state) => ({
  product: state.product,
  transaction: state.transaction,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getProductById,
  getProductTransactions,
})(Product);

const PrintIcon = (
  <svg
    className="svg-inline--fa fa-print fa-w-16"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="print"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    data-fa-i2svg=""
  >
    <path
      fill="currentColor"
      d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
    ></path>
  </svg>
);
