import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../containers/auth/auth-duck/authActions';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

class Navbar2 extends Component {
  constructor(props) {
    super(props);

    this.onLogoutClick = this.onLogoutClick.bind(this);
  }
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;

    return (
      <div className="row bg-dark nv">
        <div className="container">
          <Navbar variant="dark" expand="lg">
            <Navbar.Brand href="/" className="ml-3">
              {' '}
              {/* <img
                src="/img/logo_w.png"
                className="nav-logo"
                alt="Connect"
              />{' '} */}
              <b>MIM</b>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {isAuthenticated ? (
                <>
                  <Nav className="ml-auto ">
                    <NavDropdown title="חומר גלם " id="basic-nav-category">
                      <NavDropdown.Item href="/add-base-product">
                        {' '}
                        הוספת חומר גלם
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/base-products">
                        חומרי גלם
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="מוצרים " id="basic-nav-product">
                      <NavDropdown.Item href="/add-product">
                        {' '}
                        הוספת מוצר
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/products">
                        מוצרים
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="לקוחות | ספקים "
                      id="basic-nav-customer"
                    >
                      <NavDropdown.Item href="/add-customer">
                        {' '}
                        הוספת לקוח
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/customers">
                        לקוחות
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="מסמכים " id="basic-nav-transaction">
                      <NavDropdown.Item href="/transactions">
                        כל המסמכים
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/buy">רכישה</NavDropdown.Item>
                      <NavDropdown.Item href="/order">יצור</NavDropdown.Item>
                      <NavDropdown.Item href="/sale">מכירה</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <Nav.Link
                      className="justify-content-end"
                      href="#"
                      onClick={this.onLogoutClick}
                    >
                      <img
                        className="rounded-circle"
                        src={process.env.PUBLIC_URL + '/img/avatar.jpg'}
                        alt={user.user.name}
                        style={{ width: '25px', marginRight: '5px' }}
                        title="You must have a Gravatar connected to your email to display an image"
                      />
                      {' ' + user.user.name + ' - '}
                      יצאה
                    </Nav.Link>
                  </Nav>
                </>
              ) : (
                <Nav className="mr-auto navbar-nav ">
                  <Nav.Link href="/register">הרשמה</Nav.Link>
                  <Nav.Link href="/login">כניסה</Nav.Link>
                </Nav>
              )}
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

Navbar2.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar2);
