import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { setAlert } from '../alert/alert-duck/alertActions';

import {
  getBaseProductById,
  clearCurrentBaseProduct,
  createBaseProduct,
} from './base-product-duck/baseProductActions';

import TextFieldGroup from '../../components/common/TextFieldGroup';
import TextAreaFieldGroup from '../../components/common/TextAreaFieldGroup';

class CreateBaseProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      name: '',
      description: '',
      color: '',
      minQuantity: '',

      recordId: null,
      id: null,
      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getBaseProductById(this.props.match.params.id);
    } else {
      this.props.clearCurrentBaseProduct();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.baseProduct) {
      const baseProduct = nextProps.baseProduct.baseProduct;
      const loading = nextProps.baseProduct.loading;
      if (baseProduct)
        this.setState({
          loading,
          name: baseProduct.name,
          description: baseProduct.description ? baseProduct.description : '',
          color: baseProduct.color,
          minQuantity: baseProduct.minQuantity,

          recordId: baseProduct.recordId,
          id: baseProduct._id,
        });

      if (loading)
        this.setState({
          loading,
        });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const baseProductData = {
      name: this.state.name,
      description: this.state.description,
      color: this.state.color,
    };

    if (this.state.minQuantity) {
      baseProductData.minQuantity = this.state.minQuantity;
    }

    if (this.state.id) baseProductData.id = this.state.id;

    this.props.createBaseProduct(
      baseProductData,
      this.props.history,
      !this.state.id
    );
  }

  handleDelete(e) {
    const target = e.target;
    e.preventDefault();
    target.disabled = true;
    if (!!window.confirm('האם תרצה למחוק הלקוח הזה!')) {
      this.setState({ loading: true });
      axios
        .delete(`/api/base-products/${this.state.id}`)
        .then((res) => {
          this.props.setAlert(' נמחק בהצלחה', 'info');
          this.props.history.push('/base-products');
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.setAlert('אין אפשרות למחוק ', 'danger');
          target.disabled = false;
        });
    }
  }

  onChange(e) {
    const target = e.target;

    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'image') {
      this.setState({ image: target.files });
      return;
    }

    this.setState({ [name]: value });
  }

  showWidget = () => {
    this.widget.open();
  };

  render() {
    const { errors, name, description, color, minQuantity, loading, id } =
      this.state;

    const bigTitle = id ? 'ערכת חומר גלם' : 'הוספת חומר גלם חדש';
    const smallTitle = id
      ? 'טופס ערכת חומר גלם במערכת'
      : 'טופס הקמת חומר גלם במערכת';

    return (
      <div className="create-base-product" id="create-base-product">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">{bigTitle}</h1>
              <p className="lead text-center">{smallTitle}</p>
              <small className="d-block pb-3">שדות חובה = *</small>
              {loading ? (
                'Loading...'
              ) : (
                <form onSubmit={this.onSubmit} encType="multipart/form-data">
                  <TextFieldGroup
                    placeholder="שם *"
                    name="name"
                    value={name}
                    onChange={this.onChange}
                    error={errors.name}
                  />

                  <TextAreaFieldGroup
                    placeholder="אפיון החומר"
                    name="description"
                    value={description}
                    onChange={this.onChange}
                    error={errors.description}
                  />

                  <TextFieldGroup
                    placeholder="צבע"
                    name="color"
                    value={color}
                    onChange={this.onChange}
                    error={errors.color}
                  />

                  <TextFieldGroup
                    placeholder="כמות מינימאלית"
                    name="minQuantity"
                    value={minQuantity}
                    onChange={this.onChange}
                    error={errors.minQuantity}
                  />

                  <input
                    type="submit"
                    value="בצע"
                    className="btn btn-info btn-block mt-4"
                  />
                </form>
              )}
              {id && (
                <button
                  className="btn btn-danger btn-block mt-4"
                  onClick={this.handleDelete}
                >
                  מחק
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateBaseProduct.propTypes = {
  getBaseProductById: PropTypes.func.isRequired,
  clearCurrentBaseProduct: PropTypes.func.isRequired,
  createBaseProduct: PropTypes.func,
  setAlert: PropTypes.func,

  baseProduct: PropTypes.object,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  baseProduct: state.baseProduct,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getBaseProductById,
  clearCurrentBaseProduct,
  createBaseProduct,
  setAlert,
})(withRouter(CreateBaseProduct));
