import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllProducts } from './products-duck/productAction';
import InputGroup from '../../components/common/InputGroup';
import Spinner from '../../components/common/Spinner';
import { Link } from 'react-router-dom';

// React Table
import ReactTable from 'react-table-6';

// React table style ".css"
import 'react-table/react-table.css';

class ProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      products: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  fillTableWithSearchFilter(search) {
    var updatedList = this.props.product.products;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.recordId.toLowerCase().search(search.toLowerCase()) !== -1 ||
        (item.description
          ? item.description.toLowerCase().search(search.toLowerCase()) !== -1
          : false)
      );
    });
    this.setState({ products: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ products: this.props.product.products });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.product) {
      this.setState({ products: nextProps.product.products });
    }
  }
  componentDidMount() {
    this.props.getAllProducts();
  }

  render() {
    const { loading } = this.props.product;
    const products = this.state.products;

    const { errors } = this.state;

    let content;

    /*function folderFormatter(folder, ff) {
      return `${folder.prefix} ${folder.num}`;
    }*/

    const columns = [
      {
        Header: "מק''ט",
        accessor: 'recordId',
        maxWidth: 70,
      },
      {
        Header: 'תמונה',
        accessor: 'image',
        maxWidth: 90,
        Cell: (row) => {
          return <img src={row.value.thumbnail} alt={row.value.name} />;
        },
      },
      {
        Header: 'שם מוצר',
        accessor: 'name', // String-based value accessors!
      },

      {
        Header: 'מלאי',
        accessor: 'currentQuantity',
        maxWidth: 70,
      },
      /*{
        Header: 'תאור',
        accessor: 'description',
        minWidth: 150,
        Cell: (row) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: decodeURIComponent(row.value),
              }}
            />
          );
        },
      },*/

      {
        Header: ' ',
        id: '_id',
        accessor: '_id',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              <Link to={`add-product/${row.row._id}`}>ערוך</Link> |{' '}
              <Link to={`product/${row.row._id}`}>צפה</Link>
            </div>
          );
        },
      },
    ];

    if (products === null || loading) {
      content = <Spinner />;
    } else if (products.length > 0) {
      content = (
        <div>
          <ReactTable
            loading={loading}
            data={products}
            columns={columns}
            pageSize={10}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא לוקוחות..</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h1 className="display-4 text-center">רשימת מוצרים</h1>

            <div className="container">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <form onSubmit={this.onSubmit}>
                    <InputGroup
                      placeholder="מחפשים מוצר? הקלידו כאן ונמצא לכם"
                      name="search"
                      value={this.state.search}
                      onChange={this.onChange}
                      error={errors.search}
                      icon="fas fa-search"
                    />
                  </form>
                </div>
              </div>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

ProductsTable.propTypes = {
  getAllProducts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
  auth: state.auth,
});

export default connect(mapStateToProps, { getAllProducts })(ProductsTable);
