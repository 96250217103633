import React from 'react';

const BaseProductViewTable = ({ components }) => {
  const titles = ['מק׳׳ט', 'שם', 'כמות'];
  return (
    <table className="table">
      <thead>
        <tr>
          {titles.map((t, i) => (
            <th
              key={i}
              className="border-0 text-uppercase small font-weight-bold"
            >
              {t}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {components.map((item, index) => (
          <tr key={index}>
            <td>{item.baseProduct.recordId}</td>
            <td>{item.baseProduct.name}</td>

            <td>{item.quantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BaseProductViewTable;
