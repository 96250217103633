import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomerView from './CustomerView';
import Autosuggest from 'react-autosuggest';

import './css/customer.css';

class CustomerSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: [],
      selectedCustomer: this.props.customer,
    };

    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.clearCustomerCard = this.clearCustomerCard.bind(this);
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp(escapedValue, 'i');
    return this.props.customers.filter((customer) =>
      regex.test(customer.name + ' ' + customer.phoneNumber)
    );
  }

  getSuggestionValue(suggestion) {
    this.setSelectedCustomer(suggestion);
    return suggestion.name;
  }

  setSelectedCustomer(customer) {
    const { selectedCustomer } = this.state;
    if (customer === selectedCustomer) {
    } else {
      this.setState({ selectedCustomer: customer });
      this.props.setSelectedCustomer(customer);
    }
  }

  renderSuggestion(suggestion) {
    return (
      <span dir="rtl">
        {suggestion.name}{' '}
        <small>
          {' '}
          {suggestion.phoneNumber
            .replace(/(\d{3})?(\d{3})(\d{4})$/, '$1-$2-$3')
            .replace(/\(\) /, '')}
        </small>
      </span>
    );
  }

  onCustomerChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  clearCustomerCard(e) {
    e.preventDefault();

    if (this.state.selectedCustomer) {
      this.setState({ selectedCustomer: null, value: '' });
    }
    this.setSelectedCustomer(null);
  }
  componentWillReceiveProps(nextProps) {}

  createCustomerCard(customer) {
    return (
      <div className="row">
        <div className="col-md-12 customer-card">
          <CustomerView customer={customer} />
          <button onClick={this.clearCustomerCard} className="btn btn-danger">
            X
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { suggestions, value, selectedCustomer } = this.state;
    const { isSupplier } = this.props;

    const inputProps = {
      placeholder: isSupplier ? 'שם הספק' : 'שם הלקוח',
      className: 'form-control',
      value,
      onChange: this.onCustomerChange,
    };
    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />

        <br />
        <div>
          {selectedCustomer ? (
            this.createCustomerCard(selectedCustomer)
          ) : (
            <div>
              <h2 className="text-center">
                נא לבחור {isSupplier ? 'ספק' : 'לקוח'}
              </h2>
            </div>
          )}
        </div>
      </div>
    );
  }
}

CustomerSelect.propTypes = {
  setSelectedCustomer: PropTypes.func,
  isSupplier: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CustomerSelect);
