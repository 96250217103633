import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import { getCustomerById } from './customers-duck/customerActions';
import {
  getCustomerTransactions,
  getTransactionReport,
} from '../transaction/transaction-duck/transactionAction';

import ProfileDocumentsTable from '../documents/ProfileDocumentsTable';
import CustomerView from './CustomerView';

import './css/customer-profile.css';

class CustomerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: '',
      end: '',
      loading: false,
      data: null,
      saleIndex: 1,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    const { start, end } = this.state;
    this.setState({ loading: true });
    this.props.getTransactionReport(start, end, this.props.match.params.id);
  }

  componentDidMount() {
    const {
      match: { params },
      getCustomerById,
      getTransactionReport,
    } = this.props;

    getCustomerById(params.id);
    getTransactionReport(null, null, params.id);
  }

  getData = () => {
    const {
      match: { params },
      getCustomerTransactions,
      getTransactionReport,
    } = this.props;
    getCustomerTransactions(params.id);
    getTransactionReport(null, null, params.id);
  };

  goToEditPage;

  render() {
    const { start, end, saleIndex } = this.state;
    const {
      customer: { customer },
      transactions: { report, loading },
    } = this.props;
    console.log('props', this.props);
    return (
      <div className={'customer-profile-wrapper'}>
        {/*Top Section*/}
        {customer ? (
          <div className="col-md-12 edit-profile">
            <CustomerView customer={customer} />
            <Link to={`/customer/${customer._id}`}>
              <span className="text-center">
                <i className="far fa-edit" />
                <br />
                ערוך לקוח
              </span>
            </Link>
          </div>
        ) : (
          ''
        )}

        <hr />
        {/* profile */}
        <div className="row">
          {/**  <div className="col-sm-2">
            {' '}
            <WidgetWrapper className={'user-info'}>
              <ul>
                <li>
                  {customer && customer.firstName}{' '}
                  {customer && customer.lastName}
                </li>
                {customer && customer.idNumber ? (
                  <li>{customer.idNumber}</li>
                ) : (
                  ''
                )}

                {customer && customer.birthDate ? (
                  <li>{new Date(customer.birthDate).toLocaleDateString()}</li>
                ) : (
                  ''
                )}

                <li>{customer && customer.phoneNumber}</li>
                <li>
                  <span dir="ltr">
                    {customer && customer.account.total.toFixed(2)} ₪
                  </span>
                </li>
                <li>
                  מס׳ מסמכים:{' '}
                  {this.props.documents.documents &&
                    this.props.documents.documents.length}
                </li>
              </ul>
            </WidgetWrapper>
          </div>
          */}
          {report && !loading && (
            <div className="container">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
                    <TextFieldGroup
                      name="start"
                      value={start}
                      onChange={this.onChange}
                      type="date"
                    />
                  </div>
                  <div className="col-md-5">
                    <TextFieldGroup
                      name="end"
                      value={end}
                      onChange={this.onChange}
                      type="date"
                    />
                  </div>
                  <div className="col-md-2 pt-1">
                    <button
                      onClick={this.onSubmit}
                      className="btn btn-info btn-block"
                    >
                      החל
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-striped statics-table">
                      <thead>
                        <tr>
                          <th>שם</th>
                          <th>כמות</th>
                        </tr>
                      </thead>
                      <tbody>
                        {report.sale.map((b, i) => {
                          if (i < 5 * saleIndex) {
                            return (
                              <tr key={i}>
                                <th>
                                  <Link to={`/product/${b.product}`}>
                                    {' '}
                                    {b.name}
                                  </Link>
                                </th>
                                <th>{b.count}</th>
                              </tr>
                            );
                          }
                        })}
                        {report.sale.length > 5 * saleIndex && (
                          <tr>
                            <th
                              onClick={() =>
                                this.setState({
                                  saleIndex: saleIndex + 1,
                                })
                              }
                            >
                              <a href="#">Show More</a>
                            </th>
                            <th></th>
                          </tr>
                        )}
                      </tbody>
                      <tr>
                        <th>Total</th>
                        <th>{report.sale.reduce((a, b) => a + b.count, 0)}</th>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-sm-12 transactions">
            <ProfileDocumentsTable getData={this.getData} />
          </div>
        </div>
        <div className="main-wrapper" />
      </div>
    );
  }
}

CustomerProfile.propTypes = {
  getCustomerById: PropTypes.func,
  getCustomerTransactions: PropTypes.func,
  getTransactionReport: PropTypes.func,
  auth: PropTypes.object,
  customer: PropTypes.object,
  transaction: PropTypes.object,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  transactions: state.transaction,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCustomerById,
  getCustomerTransactions,
  getTransactionReport,
})(CustomerProfile);
