import axios from 'axios';
import { setAlert } from '../../alert/alert-duck/alertActions';
import {
  BASE_PRODUCT_LOADING,
  CLEAR_CURRENT_BASE_PRODUCT,
  GET_BASE_PRODUCT,
  GET_BASE_PRODUCTS,
  GET_ERRORS,
} from '../../../app-duck/actions/types';

//Get All base products
export const getAllBaseProducts = () => (dispatch) => {
  dispatch(setBaseProductLoading());
  axios
    .get(`/api/base-products`)
    .then((res) => {
      return dispatch({
        type: GET_BASE_PRODUCTS,
        payload: res.data.data,
      });
    })
    .catch(() =>
      dispatch({
        type: GET_BASE_PRODUCTS,
        payload: null,
      })
    );
};

//get base product by id
export const getBaseProductById = (id) => (dispatch) => {
  dispatch(setBaseProductLoading());
  axios
    .get(`/api/base-products/${id}`)
    .then((res) => {
      return dispatch({
        type: GET_BASE_PRODUCT,
        payload: res.data.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Create base product
export const createBaseProduct =
  (baseProductData, history, isNew) => async (dispatch) => {
    try {
      const res = isNew
        ? await axios.post(`/api/base-products`, baseProductData)
        : await axios.patch(
            `/api/base-products/${baseProductData.id}`,
            baseProductData
          );

      isNew
        ? dispatch(
            setAlert('המוצר ' + res.data.data.name + ', נוסף בהצלחה', 'info')
          )
        : dispatch(
            setAlert('המוצר ' + res.data.data.name + ', עודכן בהצלחה', '')
          );

      history.push('/base-products');
      return dispatch({
        type: GET_BASE_PRODUCT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  };

// base product loading
export const setBaseProductLoading = () => {
  return {
    type: BASE_PRODUCT_LOADING,
  };
};

// Clear base product
export const clearCurrentBaseProduct = () => {
  return {
    type: CLEAR_CURRENT_BASE_PRODUCT,
  };
};
