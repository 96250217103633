import React from 'react';
import { IMAGE_BASE_URL } from '../../utils/const';

const ProductView = ({ component, fullView }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <h1 className="display-4">{component.product.name}</h1>
        <p>{component.product.recordId}</p>
        <h4>
          כמות: <span>{component.quantity}</span>
        </h4>
        {component.product.color && (
          <label>
            צבע: <span>{component.product.color}</span>
          </label>
        )}
      </div>
      <div className="col-md-8">
        <img
          src={`${IMAGE_BASE_URL}/w_800,h_500,c_fill/${component.product.image.url}`}
          alt={component.product.name}
        />
      </div>
      {fullView && (
        <div className="col-md-12">
          <hr />
          <label>תיאור:</label>
          <div
            className="product_desc"
            dangerouslySetInnerHTML={{
              __html: decodeURIComponent(component.product.description),
            }}
          />

          <br />
          <hr />
          <label>מרכיבים עבור מוצר בודד:</label>
          <table className="table table-striped product_desc">
            <thead>
              <tr>
                <th>מק׳׳ט</th>
                <th>חומר גלם</th>
                <th>כמות</th>
              </tr>
            </thead>
            <tbody>
              {component.product.components.map((comp) => (
                <tr key={comp.baseProduct.recordId}>
                  <th>{comp.baseProduct.recordId}</th>
                  <th>{comp.baseProduct.name}</th>
                  <th>{comp.amount}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const ProductOrderViewTable = ({ components, saleView = false }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        {components.map((comp) => (
          <ProductView
            key={comp.product.name}
            component={comp}
            fullView={!saleView}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductOrderViewTable;
