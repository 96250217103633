import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CustomerView = ({ customer }) => {
  return (
    <div className="row">
      <div className="col-md-10 mx-auto">
        <div className="row">
          <div className="col-md-8">
            <h4>
              <Link to={`/profile/${customer._id}`}>{customer.name}</Link>
            </h4>
            <h6 className="text-muted">
              <a href={'tel:' + customer.phoneNumber}>
                <i className="fas fa-phone" /> {customer.phoneNumber}
              </a>
            </h6>
            {customer.idNumber ? (
              <h6 className="text-muted">
                <i className="fas fa-id-card" /> {customer.idNumber}
              </h6>
            ) : (
              ''
            )}

            {customer.email ? (
              <h6 className="text-muted">
                <i className="fas fa-envelope" /> {customer.email}
              </h6>
            ) : (
              ''
            )}
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  );
};

CustomerView.propTypes = {
  customer: PropTypes.object.isRequired,
};

CustomerView.defaultProps = {
  customer: {
    lastVisit: {
      date: new Date(),
      visit: null,
    },
    name: '',
    idNumber: '',
    email: '',
    phoneNumber: '',
  },
};

export default CustomerView;
