import axios from 'axios';
//import { saveAs } from 'file-saver';

import {
  GET_TRANSACTION,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_REPORTS,
  TRANSACTION_LOADING,
  GET_ERRORS,
} from '../../../app-duck/actions/types';

import { setAlert } from '../../alert/alert-duck/alertActions';

//Get Transactions Reports
export const getTransactionReport = (start, end, customer) => (dispatch) => {
  dispatch(setTransactionLoading());
  let query = '';
  if (start) {
    query = 'start=' + start + '&';
  }
  if (end) {
    query += 'end=' + end + '&';
  }
  if (customer) {
    query += 'customer=' + customer;
  }

  axios
    .get(`/api/transactions/report${query && '?' + query}`)
    .then((res) => {
      dispatch({
        type: GET_TRANSACTIONS_REPORTS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Create Transaction
export const createTransaction = (transactionData, history) => (dispatch) => {
  axios
    .post('/api/transactions/', transactionData)
    .then((res) => {
      dispatch(setAlert('מסמך רכישה נקלט בהצלחה', 'success'));
      dispatch({
        type: GET_TRANSACTION,
        payload: res.data.data,
      });
      history.push(`/transaction/${res.data.data._id}`);
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Get Transaction by id
export const getTransactionById = (id) => (dispatch) => {
  dispatch(setTransactionLoading());
  axios
    .get(`/api/transactions/${id}`)
    .then((res) => {
      dispatch({
        type: GET_TRANSACTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Get all Transaction
export const getAllTransactions = () => (dispatch) => {
  dispatch(setTransactionLoading());
  axios
    .get(`/api/transactions`)
    .then((res) =>
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Get Transaction by customer id
export const getCustomerTransactions = (customerId) => (dispatch) => {
  dispatch(setTransactionLoading());
  axios
    .get(`/api/transactions/customer/${customerId}`)
    .then((res) =>
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getProductTransactions = (productId) => (dispatch) => {
  dispatch(setTransactionLoading());
  axios
    .get(`/api/transactions/product/${productId}`)
    .then((res) =>
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Transaction Loading
export const setTransactionLoading = () => {
  return {
    type: TRANSACTION_LOADING,
  };
};
