import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllBaseProducts } from './base-product-duck/baseProductActions';
import InputGroup from '../../components/common/InputGroup';
import Spinner from '../../components/common/Spinner';
import { Link } from 'react-router-dom';

// React Table
import ReactTable from 'react-table-6';

// React table style ".css"
import 'react-table/react-table.css';

class BaseProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      baseProducts: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  fillTableWithSearchFilter(search) {
    var updatedList = this.props.baseProduct.baseProducts;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.recordId.toLowerCase().search(search.toLowerCase()) !== -1 ||
        (item.description
          ? item.description.toLowerCase().search(search.toLowerCase()) !== -1
          : false)
      );
    });
    this.setState({ baseProducts: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ products: this.props.baseProduct.baseProducts });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.baseProduct) {
      this.setState({ baseProducts: nextProps.baseProduct.baseProducts });
    }
  }
  componentDidMount() {
    this.props.getAllBaseProducts();
  }

  render() {
    const { loading } = this.props.baseProduct;

    const { errors, baseProducts } = this.state;

    let content;

    /*function folderFormatter(folder, ff) {
      return `${folder.prefix} ${folder.num}`;
    }*/

    const columns = [
      {
        Header: 'שם',
        accessor: 'name', // String-based value accessors!
      },
      {
        Header: "מק''ט",
        accessor: 'recordId',
      },
      {
        Header: 'מלאי',
        accessor: 'currentQuantity',
      },
      {
        Header: 'תאור',
        accessor: 'description',
        minWidth: 150,
      },

      {
        Header: 'ערוך',
        id: '_id',
        accessor: '_id',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              <Link to={`base-product/${row.row._id}`}>ערוך</Link>
            </div>
          );
        },
      },
    ];

    if (baseProducts === null || loading) {
      content = <Spinner />;
    } else if (baseProducts.length > 0) {
      content = (
        <div>
          <ReactTable
            loading={loading}
            data={baseProducts}
            columns={columns}
            pageSize={10}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא חומרי גלם..</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h1 className="display-4 text-center">רשימת חומרי גלם</h1>

            <div className="container">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <form onSubmit={this.onSubmit}>
                    <InputGroup
                      placeholder="מחפשים חומר גלם? הקלידו כאן ונמצא לכם"
                      name="search"
                      value={this.state.search}
                      onChange={this.onChange}
                      error={errors.search}
                      icon="fas fa-search"
                    />
                  </form>
                </div>
              </div>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

BaseProductsTable.propTypes = {
  getAllBaseProducts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  baseProduct: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  baseProduct: state.baseProduct,
  auth: state.auth,
});

export default connect(mapStateToProps, { getAllBaseProducts })(
  BaseProductsTable
);
