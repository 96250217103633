import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import { createCustomer } from './customers-duck/customerActions';

class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      address: '',
      phoneNumber: '',
      isSupplier: false,

      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const customerData = {
      name: this.state.name,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      isSupplier: this.state.isSupplier,
    };

    this.props.createCustomer(customerData, this.props.history, true);
  }

  onChange(e) {
    const target = e.target;

    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'image') {
      this.setState({ image: target.files });
      return;
    }

    this.setState({ [name]: value });
  }

  render() {
    const { errors, isSupplier } = this.state;

    return (
      <div className="create-profile">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">
                הוספת {isSupplier ? 'ספק' : 'לקוח'} חדש
              </h1>
              <p className="lead text-center">
                טופס הקמת {isSupplier ? 'ספק' : 'לקוח'} חדש במערכת
              </p>
              <small className="d-block pb-3">* = שדות חובה</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="* שם"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />

                <TextFieldGroup
                  placeholder="* כתובת"
                  name="address"
                  value={this.state.address}
                  onChange={this.onChange}
                  error={errors.address}
                />

                <TextFieldGroup
                  placeholder="* מס' נייד"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.onChange}
                  error={errors.phoneNumber}
                  type="number"
                  info="מספר טלפון נייד כולל קידומת"
                />
                <div className="checkbox mt-3">
                  <label>
                    <input
                      type="checkbox"
                      value={isSupplier}
                      checked={isSupplier ? 1 : 0}
                      onChange={this.onChange}
                      name="isSupplier"
                    />
                    <span className="cr">
                      <i className="cr-icon fa fa-check" />
                    </span>
                    <span className="mr-2">ספק</span>
                  </label>
                </div>

                <input
                  type="submit"
                  value="בצע"
                  className="btn btn-info btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateCustomer.propTypes = {
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  errors: state.errors,
});

export default connect(mapStateToProps, { createCustomer })(
  withRouter(CreateCustomer)
);
