export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const POST_LOADING = 'POST_LOADING';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';

///////******** Alerts ********///////
export const SET_ALER = 'SET_ALER';
export const REMOVE_ALERT = 'REMOVE_ALERT';

///////******** Customers ********///////
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CLEAR_CURRENT_CUSTOMER = 'CLEAR_CURRENT_CUSTOMER';

///////******** Folders ********///////
export const GET_FOLDER = 'GET_FOLDER';
export const GET_CUSTOMER_FOLDERS = 'GET_CUSTOMER_FOLDERS';
export const GET_FOLDERS = 'GET_FOLDERS';
export const FOLDER_LOADING = 'FOLDER_LOADING';
export const CLEAR_CURRENT_FOLDER = 'CLEAR_CURRENT_FOLDER';

///////******** Products ********///////
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const CLEAR_CURRENT_PRODUCT = 'CLEAR_CURRENT_PRODUCT';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCT_NOTIFICATION = 'PRODUCT_NOTIFICATION';
export const CLEAR_PRODUCT_NOTIFICATION = 'CLEAR_PRODUCT_NOTIFICATION';
export const PRODUCTS_IN_HOME = 'PRODUCTS_IN_HOME';

///////******** Transactions ********///////
//*** Orders ***//
export const ORDER_NOTIFICATION = 'ORDER_NOTIFICATION';
export const CLEAR_ORDER_NOTIFICATION = 'CLEAR_ORDER_NOTIFICATION';
export const ORDER_CALENDAR = 'ORDER_CALENDAR';
export const ORDER_LOADING = 'ORDER_LOADING';

//*** Invoice ***//
export const INVOICE_LOADING = 'INVOICE_LOADING';
export const INVOICE_DATA = 'INVOICE_DATA';

//*** Document ***//
export const DOCUMENT_LOADING = 'DOCUMENT_LOADING';
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const CLEAR_CURRENT_DOCUMENT = 'CLEAR_CURRENT_DOCUMENT';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';

///////******** Category ********///////
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY_PRODUCTS = 'GET_CATEGORY_PRODUCTS';
export const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY';
export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const CATEGORY_NOTIFICATION = 'CATEGORY_NOTIFICATION';
export const CLEAR_CATEGORY_NOTIFICATION = 'CLEAR_CATEGORY_NOTIFICATION';

//**************** Suppliers ****************/
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const SUPPLIER_LOADING = 'SUPPLIER_LOADING';
export const CLEAR_CURRENT_SUPPLIER = 'CLEAR_CURRENT_SUPPLIER';

//**************** Todo ****************/
export const GET_TODO = 'GET_TODO';
export const GET_TODOS = 'GET_TODOS';
export const TODO_LOADING = 'TODO_LOADING';
export const CLEAR_CURRENT_TODO = 'CLEAR_CURRENT_TODO';

///////******** Base products ********///////
export const GET_BASE_PRODUCT = 'GET_BASE_PRODUCT';
export const GET_BASE_PRODUCTS = 'GET_BASE_PRODUCTS';
export const GET_BASE_PRODUCT_PRODUCTS = 'GET_BASE_PRODUCT_PRODUCTS';
export const CLEAR_CURRENT_BASE_PRODUCT = 'CLEAR_CURRENT_BASE_PRODUCT';
export const BASE_PRODUCT_LOADING = 'BASE_PRODUCT_LOADING';
export const BASE_PRODUCT_NOTIFICATION = 'BASE_PRODUCT_NOTIFICATION';
export const CLEAR_BASE_PRODUCT_NOTIFICATION =
  'CLEAR_BASE_PRODUCT_NOTIFICATION';

///////******** Transactions ********///////
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const TRANSACTION_LOADING = 'TRANSACTION_LOADING';
export const GET_TRANSACTIONS_REPORTS = 'GET_TRANSACTIONS_REPORTS';
export const CLEAR_CURRENT_TRANSACTION = 'CLEAR_CURRENT_TRANSACTION';
