import React from 'react';
import zeroPad from '../../utils/util';
import ProductOrderViewTable from '../products/ProductOrderViewTable';

const SaleView = ({ transaction }) => {
  const date = new Date(transaction.createdAt);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card mt-5">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-4">
                <dl className="row p-0 m-0">
                  <dt className="col-sm-5">תאריך</dt>
                  <dd className="col-sm-5">{date.toLocaleDateString()}</dd>

                  <dt className="col-sm-5">שעה</dt>
                  <dd className="col-sm-5">
                    {zeroPad(date.getHours(), 2)}:
                    {zeroPad(date.getMinutes(), 2)}
                  </dd>
                </dl>
              </div>

              <div className="col-sm-4">
                <h2 className="text-center">{transaction.sequence}</h2>
              </div>
              <div className="col-sm-4 text-left">
                <label>מספר פריטים: {transaction.components.length}</label>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="row p-5">
              <div className="col-md-12">
                <label>רשימת המוצרים</label>
                <ProductOrderViewTable
                  saleView
                  components={transaction.components}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleView;
