import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactDataGrid from 'react-data-grid';

import '@ckeditor/ckeditor5-build-classic/build/translations/he';

import {
  createProduct,
  getProductById,
  clearCurrentProduct,
  deleteProduct,
} from './products-duck/productAction';
import { getAllBaseProducts } from '../base-products/base-product-duck/baseProductActions';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import Spinner from '../../components/common/Spinner';
import { IMAGE_BASE_URL } from '../../utils/const';

import './css/products.css';
import isEmpty from '../../validation/is-empty';
import { setAlert } from '../alert/alert-duck/alertActions';

class CreateProduct extends Component {
  constructor(props) {
    super(props);
    this.widgetId = '_' + new Date().getTime();
    this.state = {
      name: '',
      description: '',
      image: null,
      color: '',
      costPrice: '',
      minQuantity: '',

      components: [],
      selectedComponents: [],

      value: '',
      suggestions: [],

      id: null,
      isEditMode: false,
      loading: false,
      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onImgae = this.onImgae.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  componentDidMount() {
    if (this.props.baseProduct.baseProducts) {
      this.setState({ components: this.props.baseProduct.baseProducts });
    } else {
      this.props.getAllBaseProducts();
    }

    if (this.props.match.params.id) {
      this.setState({ isEditMode: true, loading: true });
      this.props.getProductById(this.props.match.params.id);
    } else {
      this.props.clearCurrentProduct();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.baseProduct) {
      this.setState({ components: nextProps.baseProduct.baseProducts });
    }

    if (nextProps.product && nextProps.product.product) {
      const product = nextProps.product.product;
      const loading = nextProps.product.loading;
      const _prod = {
        id: product._id,
        name: product.name,
        description: decodeURIComponent(product.description),
        color: product.color,
        costPrice: product.costPrice,
        minQuantity: product.minQuantity,

        image: product.image,
      };

      let selectedComponents = [];
      selectedComponents = product.components.map((comp) => ({
        ...comp.baseProduct,
        amount: comp.amount,
      }));

      this.setState({
        ..._prod,
        loading,
        selectedComponents,
      });
      if (loading) this.setState({ loading });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    if (isEmpty(this.state.image)) {
      this.props.setAlert('בחר תמונה', 'error');
      return;
    }

    const productData = {
      name: this.state.name,
      description: encodeURIComponent(this.state.description),
      image: this.state.image,
      color: this.state.color,
      costPrice: this.state.costPrice,
      minQuantity: this.state.minQuantity,

      components: [],
    };
    productData.components = this.state.selectedComponents.map((comp) => ({
      baseProduct: comp._id,
      amount: comp.amount,
    }));

    if (this.state.id !== null) {
      productData.id = this.state.id;
      this.props.createProduct(productData, this.props.history, false);
    } else this.props.createProduct(productData, this.props.history, true);
  }

  onChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  onImgae(name, value) {
    this.setState({ [name]: value });
  }

  removeImage() {
    this.setState({ image: null });
  }

  imagesView() {
    const { image } = this.state;
    return (
      <div className="row">
        {image && (
          <div className="col-sm-6 col-md-4 mt-2">
            <span className="close" onClick={() => this.removeImage()}></span>
            <img
              src={`${IMAGE_BASE_URL}/w_350,h_200,c_fill/${image.url}`}
              alt={image.name}
            />
          </div>
        )}
      </div>
    );
  }

  deleteProduct(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const result = window.confirm(`תרצה למחוק ${this.state.name} ?`);
    if (result) {
      this.props.deleteProduct(this.state.id, this.props.history);
    }
  }

  showWidget = () => {
    this.widget.open();
  };

  widget = window.cloudinary.createUploadWidget(
    {
      cloudName: 'karam-dev',
      uploadPreset: 'vazpo6tv',
      sources: ['local', 'url'],
      tags: ['xmas'],
    },
    (error, result) => {
      if (!error) {
        if (result && result.event === 'success') {
          const data = result.info;
          //const { image } = this.state;
          const _image = {
            name: data.original_filename,
            url: data.path,
            size: data.bytes,
            thumbnail: data.thumbnail_url,
            hash: data.signature,
          };
          this.setState({ image: _image });
        }
      }
    }
  );

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    // Check if quantity is number
    if (updated.amount) {
      if (isNaN(updated.amount)) {
        return;
      }
    }

    // Update components
    this.setState((state) => {
      const selectedComponents = state.selectedComponents.slice();
      for (let i = fromRow; i <= toRow; i++) {
        selectedComponents[i] = {
          ...selectedComponents[i],
          ...updated,
        };
      }
      return { selectedComponents };
    });
  };

  getCellActions = (column, row) => {
    const cellActions = [
      {
        icon: <i className="fas fa-trash-alt text-dark" />,
        callback: () => {
          this.setState({
            selectedComponents: this.arrayRemove(
              this.state.selectedComponents,
              row
            ),
          });
        },
      },
    ];
    return column.key === 'action' ? cellActions : null;
  };
  arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  onComponentChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp(escapedValue, 'i');
    return this.state.components.filter((component) =>
      regex.test(component.name + ' ' + component.recordId)
    );
  }
  onSuggestionSelected(
    suggestion,
    suggestionValue,
    suggestionIndex,
    sectionIndex,
    method
  ) {
    this.setSelectedComponent(suggestionValue.suggestion);
    this.clearInput();
  }
  setSelectedComponent(component) {
    const { selectedComponents } = this.state;
    component = {
      ...component,
      amount: 1,
    };
    selectedComponents.push(component);
    this.setState({ value: '' });
  }
  getSuggestionValue(suggestion) {
    return suggestion.name + ' ' + suggestion.recordId;
  }
  renderSuggestion(suggestion) {
    return (
      <span>
        {suggestion.name + ' '} <small>{suggestion.recordId}</small>
      </span>
    );
  }
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  clearInput = () => {
    this.setState({
      value: '',
    });
  };

  render() {
    const {
      errors,
      loading,
      isEditMode,
      description,
      selectedComponents,
      suggestions,
      value,
    } = this.state;
    const componentsOption = [];
    this.state.components &&
      this.state.components.forEach((base) => {
        componentsOption.push({
          label: base.name,
          value: base._id,
          id: base.id,
        });
      });

    const config = {
      language: 'he',
      toolbar: [
        'bold',
        'italic',
        'bulletedList',
        'numberedList',
        'insertTable',
      ],
    };

    const componentOptions = [
      { key: 'action', name: 'מחק' },
      { key: 'amount', name: 'כמות', editable: true },
      { key: 'recordId', name: 'מק׳׳ט' },
      { key: 'name', name: 'שם' },
    ];

    const inputProps = {
      placeholder: 'שם חומר גלם | מק׳׳ט',
      className: 'form-control',
      value,
      onChange: this.onComponentChange,
    };

    const bigTitle = isEditMode ? 'ערכת מוצר' : 'הוספת מוצר חדש';
    const smallTitle = isEditMode
      ? 'טופס ערכת מוצר במערכת'
      : 'טופס הקמת מוצר חדש במערכת';

    return !!errors.noproduct ? (
      <h1>{errors.noproduct}</h1>
    ) : loading ? (
      <Spinner></Spinner>
    ) : (
      <div className="create-product">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <h1 className="display-4 text-center">{bigTitle}</h1>
              <p className="lead text-center">{smallTitle}</p>
              <small className="d-block pb-3">שדות חובה = *</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="שם המוצר *"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                  label="שם המוצר *"
                />

                <div className="row">
                  <div className="col-md-12 editor">
                    <label>תיאור המוצר</label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={config}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({ description: data });
                      }}
                    />
                  </div>
                </div>
                <br />
                <br />
                <TextFieldGroup
                  placeholder="צבע"
                  name="color"
                  value={this.state.color}
                  onChange={this.onChange}
                  error={errors.color}
                  label="צבע"
                />
                <TextFieldGroup
                  placeholder="מחיר עלות"
                  name="costPrice"
                  value={this.state.costPrice}
                  onChange={this.onChange}
                  error={errors.costPrice}
                  label="מחיר עלות"
                />
                <TextFieldGroup
                  placeholder="כמות מינימאלית במלאי"
                  name="minQuantity"
                  value={this.state.minQuantity}
                  onChange={this.onChange}
                  error={errors.minQuantity}
                  label="כמות מינימאלית במלאי"
                />

                <hr />

                <label>מרכיבים</label>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                />
                <br />

                <div dir="ltr">
                  <ReactDataGrid
                    columns={componentOptions}
                    rowGetter={(i) => selectedComponents[i]}
                    onGridRowsUpdated={this.onGridRowsUpdated}
                    minHeight={300}
                    rowsCount={20}
                    getCellActions={this.getCellActions}
                    callSelection={true}
                    enableCellSelect={true}
                  />
                </div>
                <hr />
                <h4>תמונת המוצר</h4>

                <span
                  className="btn btn-warning mb-3 mt-3"
                  onClick={this.showWidget}
                >
                  Upload Image
                </span>
                {this.imagesView()}

                <input
                  type="submit"
                  value="בצע"
                  className="btn btn-info btn-block mt-4"
                />
                {isEditMode ? (
                  <button
                    className="btn btn-danger mt-4 btn-delete"
                    onClick={this.deleteProduct}
                  >
                    מחק
                  </button>
                ) : (
                  <></>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateProduct.propTypes = {
  createProduct: PropTypes.func.isRequired,
  getAllBaseProducts: PropTypes.func,
  deleteProduct: PropTypes.func,
  getProductById: PropTypes.func,
  clearCurrentProduct: PropTypes.func,
  setAlert: PropTypes.func,

  product: PropTypes.object,
  baseProduct: PropTypes.object,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
  baseProduct: state.baseProduct,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createProduct,
  getAllBaseProducts,
  getProductById,
  clearCurrentProduct,
  deleteProduct,
  setAlert,
})(withRouter(CreateProduct));
