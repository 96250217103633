import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllTransactions } from './transaction-duck/transactionAction';

import InputGroup from '../../components/common/InputGroup';
import Spinner from '../../components/common/Spinner';
import { TRANSACTION_TYPES } from '../../utils/const';
import * as COLOR from '../../utils/colors';

// React Table
import ReactTable from 'react-table';

// React table style ".css"
import 'react-table/react-table.css';

class TransactionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      documents: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  fillTableWithSearchFilter(search) {
    let updatedList = this.props.document.documents;
    updatedList = updatedList.filter(function (item) {
      return (
        item.type.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.sequence.toString().search(search.toLowerCase()) !== -1 ||
        (item.customer
          ? item.customer.name.toLowerCase().search(search.toLowerCase()) !== -1
          : false) ||
        (item.customer
          ? item.customer.phoneNumber
              .toString()
              .search(search.toLowerCase()) !== -1
          : false) ||
        new Date(item.createdAt)
          .toLocaleDateString()
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1
      );
    });
    this.setState({ documents: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ documents: this.props.transaction.transactions });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.transaction) {
      this.setState({ documents: nextProps.transaction.transactions });
    }
  }

  componentDidMount() {
    this.props.getAllTransactions();
  }

  render() {
    const { loading } = this.props.transaction;
    const { documents, errors } = this.state;

    let content;

    const columns = [
      {
        Header: 'מס׳',
        accessor: 'sequence',
        maxWidth: 100,
      },
      {
        Header: 'סוג מסמך',
        accessor: 'type',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              {row.row.type === TRANSACTION_TYPES.BUY
                ? 'רכישה'
                : row.row.type === TRANSACTION_TYPES.ORDER
                ? 'יצור'
                : row.row.type === TRANSACTION_TYPES.SALE
                ? 'מכירה'
                : null}
            </div>
          );
        },
      },

      {
        Header: 'שם לקוח | ספק',
        accessor: 'customer.name',
      },
      {
        Header: 'מס׳ טלפון',
        accessor: 'customer.phoneNumber',
        maxWidth: 120,
      },
      {
        Header: ' ',
        id: '_id',
        accessor: '_id',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              <Link to={`/transaction/${row.row._id}`}>צפה</Link>
            </div>
          );
        },
      },
    ];

    if (documents === null || loading) content = <Spinner />;
    else if (documents.length > 0) {
      content = (
        <div className="col-md-12">
          <ReactTable
            loading={loading}
            data={documents}
            columns={columns}
            pageSize={50}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  background: rowInfo
                    ? rowInfo.row.type === TRANSACTION_TYPES.SALE
                      ? COLOR.GREEN_BACKGROUND
                      : rowInfo.row.type === TRANSACTION_TYPES.ORDER
                      ? COLOR.YELLOW_BACKGROUND
                      : rowInfo.row.type === TRANSACTION_TYPES.BUY
                      ? COLOR.GRAY_BACKGROUND
                      : null
                    : null,
                },
              };
            }}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא מסמכים...</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h4 className="display-4 text-center">רשימת המסמכים</h4>
            <div className="container">
              <div className="row">
                <div className="col-md-12 m-auto">
                  <form onSubmit={this.onSubmit}>
                    <InputGroup
                      placeholder="מחפשים מסמך? הקלידו כאן ונמצא לכם"
                      name="search"
                      value={this.state.search}
                      onChange={this.onChange}
                      error={errors.search}
                      icon="fas fa-search"
                    />
                  </form>
                </div>
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TransactionsTable.propTypes = {
  getAllTransactions: PropTypes.func,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  transaction: state.transaction,
  auth: state.auth,
});

export default connect(mapStateToProps, { getAllTransactions })(
  TransactionsTable
);
