import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Spinner from '../../components/common/Spinner';
import InputGroup from '../../components/common/InputGroup';
import { DOCUMENT_TYPE } from '../../utils/util';
import * as COLOR from '../../utils/colors';
import { downloadPDF } from './document-duck/documentAction';

// React Table
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';

// React table style ".css"
import 'react-table/react-table.css';

import './css/documents.css';
import { TRANSACTION_TYPES } from '../../utils/const';
const SelectTable = selectTableHOC(ReactTable);

class ProfileDocumentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      transactions: [],
      selectAll: false,
      selection: [],

      errors: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  static defaultProps = {
    keyField: '_id',
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  fillTableWithSearchFilter(search) {
    let updatedList = this.props.transaction.transactions;
    updatedList = updatedList.filter(function (item) {
      return (
        item.type.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.sequence.toString().search(search.toLowerCase()) !== -1 ||
        (item.customer
          ? item.customer.name.toLowerCase().search(search.toLowerCase()) !== -1
          : false) ||
        (item.customer
          ? item.customer.phoneNumber
              .toString()
              .search(search.toLowerCase()) !== -1
          : false) ||
        new Date(item.createdAt)
          .toLocaleDateString()
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1
      );
    });
    this.setState({ transactions: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ transactions: this.props.transaction.transactions });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.transaction) {
      let newDocs = nextProps.transaction.transactions;
      if (
        this.state.transactions &&
        this.state.transactions.length > 0 &&
        !nextProps.transaction.loading
      ) {
        if (this.state.transactions[0]._id !== newDocs[0]._id)
          this.state.transactions.concat(newDocs);
      }
      if (!nextProps.transaction.loading)
        this.setState({
          transactions: newDocs,
        });
    }
  }

  componentDidMount() {
    this.props.getData();
  }

  toggleSelection = (key, shift, row) => {
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  render() {
    const { loading } = this.props.transaction;
    const { transactions, errors } = this.state;

    let content;

    const columns = [
      {
        Header: 'סוג מסמך',
        accessor: 'type',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              {row.row.type === TRANSACTION_TYPES.BUY
                ? 'רכישה'
                : row.row.type === TRANSACTION_TYPES.ORDER
                ? 'יצור'
                : row.row.type === TRANSACTION_TYPES.SALE
                ? 'מכירה'
                : null}
            </div>
          );
        },
      },
      {
        Header: 'מספר מסמך',
        accessor: 'sequence',
        maxWidth: 100,
      },
      {
        Header: 'מספר פרטים במסמך',
        accessor: 'components',
        Cell: (row) => {
          return <div>{row.row.components.length}</div>;
        },
      },
      {
        Header: 'תאריך',
        accessor: 'createdAt',
      },

      {
        Header: ' ',
        id: '_id',
        accessor: '_id',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              <Link to={`/transaction/${row.row._id}`}>צפה</Link>
            </div>
          );
        },
      },
    ];

    if (transactions === null || loading) content = <Spinner />;
    else if (transactions.length > 0) {
      content = (
        <div className="col-md-12">
          <SelectTable
            selectAll={this.state.selectAll}
            toggleSelection={this.toggleSelection}
            toggleAll={this.toggleAll}
            selectType="checkbox"
            ref={(r) => (this.checkboxTable = r)}
            isSelected={this.isSelected}
            loading={loading}
            data={transactions}
            columns={columns}
            defaultPageSize={100}
            page={this.state.page}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  background: rowInfo
                    ? rowInfo.row.type === DOCUMENT_TYPE.INVOICE
                      ? COLOR.RED_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.RECEIPT
                      ? COLOR.GREEN_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.BID
                      ? COLOR.YELLOW_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.CREDIT
                      ? COLOR.GRAY_BACKGROUND
                      : null
                    : null,
                },
              };
            }}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא מסמכים...</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <form onSubmit={this.onSubmit}>
              <InputGroup
                placeholder="מחפשים מסמך? הקלידו כאן ונמצא לכם"
                name="search"
                value={this.state.search}
                onChange={this.onChange}
                error={errors.search}
                icon="fas fa-search"
              />
            </form>
          </div>

          {content}
        </div>
      </div>
    );
  }
}

ProfileDocumentsTable.propTypes = {
  getData: PropTypes.func,
  downloadPDF: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  transaction: state.transaction,
  auth: state.auth,
});

export default connect(mapStateToProps, { downloadPDF })(ProfileDocumentsTable);
