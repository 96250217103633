import axios from 'axios';
import { setAlert } from '../../alert/alert-duck/alertActions';
import {
  GET_CUSTOMER,
  GET_CUSTOMERS,
  CUSTOMER_LOADING,
  CLEAR_CURRENT_CUSTOMER,
  GET_ERRORS,
} from '../../../app-duck/actions/types';

// Get customer by id
export const getCustomerById = (id) => (dispatch) => {
  dispatch(setCustomerLoading());
  axios
    .get(`/api/customers/${id}`)
    .then((res) =>
      dispatch({
        type: GET_CUSTOMER,
        payload: res.data.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_CUSTOMER,
        payload: {},
      })
    );
};

// Get all customers by handle
export const getCustomers = () => (dispatch) => {
  dispatch(setCustomerLoading());
  axios
    .get(`/api/customers`)
    .then((res) => {
      return dispatch({
        type: GET_CUSTOMERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: GET_CUSTOMERS,
        payload: null,
      });
    });
};

// Create Customer
export const createCustomer =
  (customerData, history, isNew) => async (dispatch) => {
    try {
      const res = isNew
        ? await axios.post('/api/customers', customerData)
        : await axios.patch('/api/customers/' + customerData.id, customerData);
      console.log('res', res);
      isNew
        ? dispatch(
            setAlert('לקוח ' + res.data.data.name + ', נוסף בהצלחה', 'info')
          )
        : dispatch(
            setAlert('לקוח ' + res.data.data.name + ', עודכן בהצלחה', '')
          );

      history.push(`/customers`);
      return dispatch({
        type: GET_CUSTOMER,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  };

// Customer loading
export const setCustomerLoading = () => {
  return {
    type: CUSTOMER_LOADING,
  };
};

// Clear customer
export const clearCurrentCustomer = () => {
  return {
    type: CLEAR_CURRENT_CUSTOMER,
  };
};
