import {
  BASE_PRODUCT_LOADING,
  BASE_PRODUCT_NOTIFICATION,
  CLEAR_BASE_PRODUCT_NOTIFICATION,
  CLEAR_CURRENT_BASE_PRODUCT,
  GET_BASE_PRODUCT,
  GET_BASE_PRODUCTS,
} from '../../../app-duck/actions/types';

const initialState = {
  baseProduct: null,
  baseProducts: null,
  loading: false,
  notification: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BASE_PRODUCT: {
      return {
        ...state,
        loading: false,
        baseProduct: action.payload,
      };
    }
    case GET_BASE_PRODUCTS: {
      return {
        ...state,
        loading: false,
        baseProducts: action.payload,
      };
    }
    case CLEAR_CURRENT_BASE_PRODUCT: {
      return {
        ...state,
        baseProduct: null,
      };
    }
    case BASE_PRODUCT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case BASE_PRODUCT_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case CLEAR_BASE_PRODUCT_NOTIFICATION: {
      return {
        ...state,
        notification: null,
      };
    }

    default:
      return state;
  }
}
