import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTransactionById } from './transaction-duck/transactionAction';
import Spinner from '../../components/common/Spinner';
import CustomerView from '../customers/CustomerView';
import { TRANSACTION_TYPES } from '../../utils/const';
import BuyView from './BuyView';
import OrderView from './OrderView';
import SaleView from './SaleView';

export class TransactionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: {},
      loading: true,
      id: null,

      errors: {},
    };
  }

  componentDidMount() {
    const {
      match: { params },
      getTransactionById,
    } = this.props;

    this.setState({ id: params.id });
    getTransactionById(params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.state.id) {
      const {
        match: { params },
      } = nextProps;
      this.setState({ id: params.id });
      this.props.getTransactionById(params.id);
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
      //redirect page not found
      this.props.history.push('/not-found');
    }
    if (nextProps.transaction.transaction) {
      const doc = nextProps.transaction.transaction;

      this.setState({ transaction: doc });
    }
    if (nextProps.transaction) {
      const doc = nextProps.transaction;

      this.setState({ loading: doc.loading });
    }
  }

  render() {
    const { transaction, loading } = this.state;
    if (loading) return <Spinner />;
    const title =
      transaction.type === TRANSACTION_TYPES.BUY
        ? 'מסמך רכישה'
        : transaction.type === TRANSACTION_TYPES.ORDER
        ? 'מסמך יצור'
        : transaction.type === TRANSACTION_TYPES.SALE
        ? 'מסמך מכירה'
        : '';

    return (
      <div id="transaction">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4 text-center">{title}</h1>
              <div className="row">
                <div className="col text-left">
                  <button
                    className="hide-print"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    הדפס {PrintIcon}
                  </button>
                </div>
              </div>
              <hr />
              {transaction.type !== TRANSACTION_TYPES.ORDER && (
                <CustomerView customer={transaction.customer} />
              )}

              {transaction.type === TRANSACTION_TYPES.BUY && (
                <BuyView transaction={transaction} />
              )}
              {transaction.type === TRANSACTION_TYPES.ORDER && (
                <OrderView transaction={transaction} />
              )}
              {transaction.type === TRANSACTION_TYPES.SALE && (
                <SaleView transaction={transaction} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TransactionView.propTypes = {
  getTransactionById: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  transaction: state.transaction,
});

const mapDispatchToProps = { getTransactionById };

export default connect(mapStateToProps, mapDispatchToProps)(TransactionView);

const PrintIcon = (
  <svg
    className="svg-inline--fa fa-print fa-w-16"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="print"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    data-fa-i2svg=""
  >
    <path
      fill="currentColor"
      d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
    ></path>
  </svg>
);
