import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/common/PrivateRoute';

import Dashboard from './containers/dashboard/Dashboard';
import NotFound from './components/not-found/NotFound';

import CreateBaseProduct from './containers/base-products/CreateBaseProduct';
import BaseProductsTable from './containers/base-products/BaseProductsTable';
import Buy from './containers/transaction/Buy';
import Order from './containers/transaction/Order';
import Sale from './containers/transaction/Sale';
import TransactionsTable from './containers/transaction/TransactionsTable';
import TransactionView from './containers/transaction/TransactionView';

import CreateProduct from './containers/products/CreateProduct';
import ProductsTable from './containers/products/ProductsTable';
import Product from './containers/products/Product';
import CreateCustomer from './containers/customers/CreateCustomer';
import EditCustomer from './containers/customers/EditCustomer';
import CustomerProfile from './containers/customers/CustomerProfile';
import CustomersTable from './containers/customers/CustomersTable';

export default function () {
  return (
    <div className="container main-container">
      <Switch>
        <PrivateRoute
          exact
          path="/add-base-product"
          component={CreateBaseProduct}
        />

        <PrivateRoute
          exact
          path="/base-product/:id"
          component={CreateBaseProduct}
        />

        <PrivateRoute
          exact
          path="/base-products"
          component={BaseProductsTable}
        />

        <PrivateRoute exact path="/product/:id" component={Product} />

        <PrivateRoute exact path="/add-product" component={CreateProduct} />

        <PrivateRoute exact path="/add-product/:id" component={CreateProduct} />

        <PrivateRoute exact path="/products" component={ProductsTable} />

        <PrivateRoute exact path="/buy" component={Buy} />

        <PrivateRoute exact path="/order" component={Order} />
        <PrivateRoute exact path="/sale" component={Sale} />

        <PrivateRoute
          exact
          path="/transactions"
          component={TransactionsTable}
        />

        <PrivateRoute
          exact
          path="/transaction/:id"
          component={TransactionView}
        />

        <PrivateRoute exact path="/add-customer" component={CreateCustomer} />

        <PrivateRoute exact path="/profile/:id" component={CustomerProfile} />

        <PrivateRoute exact path="/customer/:id" component={EditCustomer} />

        <PrivateRoute exact path="/customers" component={CustomersTable} />

        <PrivateRoute exact path="/dashboard" component={Dashboard} />

        <Route path="/not-found" component={NotFound} />
      </Switch>
    </div>
  );
}
