import {
  GET_TRANSACTION,
  GET_TRANSACTIONS_REPORTS,
  GET_TRANSACTIONS,
  TRANSACTION_LOADING,
  CLEAR_CURRENT_TRANSACTION,
} from '../../../app-duck/actions/types';

const initialState = {
  transaction: null,
  transactions: null,
  report: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS_REPORTS:
      return {
        ...state,
        report: action.payload,
        loading: false,
      };
    case TRANSACTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        loading: false,
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };
    case CLEAR_CURRENT_TRANSACTION:
      return {
        ...state,
        transaction: null,
      };
    default:
      return state;
  }
}
